import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  Container,
  Subtitle,
  Title,
  StoreIcons,
  Content,
  ContentWrapper,
} from './storeCtaSectionMinimal.css';

const query = graphql`
  query {
    homeJson {
      storeCta {
        titleAlternative
        subtitle
        appstoreLink
        playstoreLink
      }
    }
  }
`;

const StoreCtaMinimalSection = () => {
  const data = useStaticQuery(query).homeJson.storeCta;

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Subtitle>{data.subtitle}</Subtitle>
          <Title>{data.titleAlternative}</Title>
        </Content>
        <StoreIcons />
      </ContentWrapper>
    </Container>
  );
};

export default StoreCtaMinimalSection;
