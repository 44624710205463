import styled from 'styled-components';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { ContentWrapper as ContentWrapperBase } from '../../global.css';
import StoreIconsBase from '../storeIcons';

export const Container = styled.div`
  width: 100%;
  background-color: ${color.purple};
  color: ${color.textInverted};
  padding: 50px 0;

  ${MEDIA.MIN_TABLET`
    padding: 60px 0;
  `};
`;

export const Title = styled.h2`
  text-align: left;
  font-size: 40px;
  line-height: 45px;
  font-family: ${font.secondary};
  text-transform: uppercase;
  white-space: pre-wrap;
`;

export const Subtitle = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
  ${MEDIA.MIN_TABLET`
    line-height: 20px;
  `};
`;

export const StoreIcons = styled(StoreIconsBase)`
  margin-top: 26px;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentWrapper = styled(ContentWrapperBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  `};
`;
