import React from 'react';
import Layout from 'components/layout';
import Hero from 'components/readership/hero';
import Features from 'components/readership/features';
import StoreMinimalCtaSection from 'components/storeCtaMinimalSection';

const Readership = () => (
  <Layout>
    <Hero />
    <Features />
    <StoreMinimalCtaSection />
  </Layout>
);

export default Readership;
