import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  background: ${color.white};
  text-align: left;

  ${MEDIA.MIN_TABLET`
    padding-top: 83px;
  `};
`;

export const Title = styled.h2`
  font-family: ${font.primaryBold};
  font-size: 40px;
  line-height: 48px;
  width: 100%;
  margin-bottom: 8px;
  text-align: center;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 56px;
  `};
`;

export const Subtitle = styled.h2`
  font-family: ${font.primaryBold};
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 60px;
  `};
`;

export const FeaturesWrapper = styled.div`
  display: grid;
  ${MEDIA.MIN_TABLET`
         grid-template-columns: 1fr 1fr 1fr;
         grid-gap: 53px;
    
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MEDIA.TABLET`
    margin-bottom: 50px;
  `};
`;

export const Icon = styled.img`
  margin-bottom: 30px;
`;

export const Caption = styled.div`
  font-family: ${font.primary};
  text-align: center;
  size: 18px;
  line-height: 26px;
  strong {
    font-weight: bold;
  }
`;
