import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  Container,
  Title,
  Subtitle,
  Item,
  Icon,
  Caption,
  FeaturesWrapper,
} from './index.css';
import { ContentWrapper } from '../../../global.css';

const query = graphql`
  query ReadershipFeaturesQuery {
    readershipJson {
      features {
        title
        subtitle
        items {
          name
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            publicURL
          }
        }
      }
    }
  }
`;

const ReadershipHero = () => {
  const data = useStaticQuery(query).readershipJson.features;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
        <FeaturesWrapper>
          {data.items.map((feature, index) => (
            <Item key={index}>
              <Icon src={feature.image.publicURL} alt={feature.name} />
              <Caption
                dangerouslySetInnerHTML={{
                  __html: feature.content.childMarkdownRemark.html,
                }}
              />
            </Item>
          ))}
        </FeaturesWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default ReadershipHero;
