import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import {
  Container,
  Title,
  Text,
  Content,
  TextWrapper,
  Subtitle,
  Image,
} from './index.css';

const query = graphql`
  query ReadershipHeroQuery {
    readershipJson {
      hero {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 329, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ReadershipHero = () => {
  const data = useStaticQuery(query).readershipJson.hero;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextWrapper>
            <Subtitle>{data.subtitle}</Subtitle>
            <Title>{data.title}</Title>
            <Text>{data.text}</Text>
          </TextWrapper>
          <Image fluid={data.image.childImageSharp.fluid} alt={data.title} imgStyle={{ objectFit: "contain" }} />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default ReadershipHero;
